<template>
  <div class="flex-center-between mb-20">
    <LearnRoute :bPath="bPath" v-show="bPath" />
    <div class="w-40" v-show="!bPath" />

    <h1 class="text-center m-0 mrl-10">{{ title }}</h1>

    <LearnRoute :fPath="fPath" v-show="fPath" />
    <div class="w-40" v-show="!fPath" />
  </div>
</template>

<script>
import LearnRoute from '@/components/LearnRoute.vue'

export default {
  props: [ 'bPath', 'fPath', 'title' ],
  components: { LearnRoute }
}
</script>
