<template>
  <a class="btn-blue" @click="route()"><i :class="`${this.bPath ? 'ml-3 b' : 'mr-3 f'}`"></i></a>
</template>

<script>
import { goTo } from '@/scripts/routeHelper.js'

export default {
  props: [ 'bPath', 'fPath' ],
  methods: {
    route: function() { goTo(this.bPath ? this.bPath : this.fPath ) },
  }
}
</script>

<style scoped>
i {
  border: solid white;
  border-width: 0 2px 2px 0px;
  display: inline-block;
  padding: 3px;
  margin-bottom: 1px;
}
.b {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.f {
  transform: rotate(315deg);
  -webkit-transform: rotate(315deg);
}
</style>
